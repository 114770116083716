/* General Styles */
.navbar {
  background: linear-gradient(0deg, rgb(229, 240, 255) 0%, rgb(172, 201, 254) 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-right: 0;
}

.navbar-logo img {
  margin-left: 100px;
  width: 170px;
  height: auto;
}

.navbar-menu {
  list-style: none;
  display: flex;
  margin-top: 10px;
  padding-right: 10px;
}

.navbar-menu li {
  margin-right: 50px;
  font-family: sans-serif;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.navbar li:has( a.active){
  background-color: rgb(138, 214, 233);
  border-radius: 5px;
}

.navbar-menu a {
  color: #000;
  font-size: 1em;
  text-decoration: none;
  cursor: pointer;
   /* font-weight: 530; */
  font-weight: bolder;
}

.navbar-toggle {
  display: none;
  cursor: pointer;
}

.bar {
  width: 20px;
  height: 3px;
  background-color: #000;
  margin: 4px 0;
}

.popup {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  font-size: 20px;
  height: 100%;
  background-color: rgb(70, 121, 204);
  transition: right 0.3s ease-in-out;
  z-index: 999;
  padding-top: 10%;
  overflow-y: scroll;
}

.popup.open {
  right: 0;
}

.popup a {
  padding: 2%;
  color: white;
  text-decoration: none;
}

.popup i {
  margin-top: 5%;
}

/* #closeBtn i {
  cursor: pointer;
  padding: 10px;
  color: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
} */

#closeBtn{
  display: none;
}
#openBtn{
  font-size:xx-large;
  display: none;
}
#checkBox{
  display: none;
}

.navbar-menu2 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.navbar-menu2 li {
  margin-top: 25px;
}

.navbar-menu2 a {
  text-decoration: none;
  font-weight: 700;
  color: white;
  transition: color 0.3s ease;
}

.navbar-menu2 a:hover {
  color: rgb(0, 0, 112);
}

  /* DROP DOWN *//* Container for the dropdown */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Style for the dropdown button */
  .dropdown-button {
    background: none;
    font-size: 17px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  /* Style for the dropdown menu */
  .dropdown-menu {
    display: none;
    width: 50%;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    min-width: 500px;
    border-radius: 4px;
    padding: 10px 0;
  }
  .sub-drop{
    display: flex;
    flex-wrap: wrap;
  }
  /* Style for the dropdown lists */
  .dropdown-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  /* Style for each item in the dropdown lists */
  .dropdown-menu li {
    padding: 8px 16px;
  }
  
  /* Style for the links in the dropdown lists */
  .dropdown-menu li a {
    color: black;
    text-decoration: none;
    display: block;
  }
  
  /* Hover effects for the list items and links */
  .dropdown-menu li:hover, .dropdown-menu li a:hover {
    background-color: #f1f1f1;
  }
  
  /* Display the dropdown menu when the button is clicked */
  .dropdown:hover .dropdown-menu {
    display: block;
  }
  
  /* Style for the two dropdown columns */
   .drop1, .drop2 {
    display: inline-block;
    vertical-align: top;
  }
  
  /* Adjust the padding between the columns */
  .drop1 {
    padding-right: 10px;
  }
  
   .drop2 {
    padding-left: 10px;
  }
  
      .sub-drop h3{
        padding-left: 7%;
        font-weight: 900;
        font-size: 20px;
        color: #0056b3;
      }  

 
/* Responsive Styles */
@media (max-width: 832px) {
  #closeBtn{
    display: flex;
    margin-left: 5%;
    font-size: 30px;
  }
  #openBtn{
    display: flex;
    top: 10px;
    right: 0;
    position: fixed;
  }

  .navbar-menu{
      display: none;
      flex-direction:column;
      z-index: 10;
      align-items: flex-start;
      top: 0;
      right: 0;
      background: blue;
      width: 100%;
      height: 100%;
      transition: 0.3s ease-out;
      position: fixed;
      margin: 0 auto;
      padding-top: 20px;
      font-size: 20px;
      line-height: 50px;
  }
  /* .navbar-menu ul li{
    color: white;
    padding-top: 30%;
  }
  li{
    color: white;
  } */
#checkBox:checked ~ .navbar-menu{
  display: flex;
}
  .popup {
      display: block;
  }

  .navbar-toggle {
      display: block;
      z-index: 999;
      padding: 0 2%;
  }
  
  /* DROP DOWN *//* Container for the dropdown */
  .dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  /* Style for the dropdown button */
  .dropdown-button {

      padding: 2% ;
      color: white;
      text-decoration: none;
      font-weight: 700;
      font-size: 18px;
    }
  
  
  /* Style for the dropdown menu */
  .dropdown-menu {
    display: none;
    width: 200px;
    min-width: 240px;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 4px;
    padding: 10px 0;
  }
  .sub-drop{
    display: flex;
    flex-wrap: wrap;
  }
  /* Style for the dropdown lists */
  .dropdown-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  /* Style for each item in the dropdown lists */
  .dropdown-menu li {
    padding: 0px 0px;
  }
  
  /* Style for the links in the dropdown lists */
  .dropdown-menu li a {
    color: black;
    text-decoration: none;
    display: block;
    font-size: 16px;
  }
  
  /* Hover effects for the list items and links */
  .dropdown-menu li:hover, .dropdown-menu li a:hover {
    background-color: #f1f1f1;
  }
  
  /* Display the dropdown menu when the button is clicked */
  .dropdown:hover .dropdown-menu {
    display: block;
  }
  
  /* Style for the two dropdown columns */
   .drop1, .drop2 {
    display: inline-block;
    vertical-align: top;
  }
  
  /* Adjust the padding between the columns */
  .drop1 {
    padding-right: 10px;
  }
  
   .drop2 {
    padding-left: 10px;
  }
  
      .sub-drop h3{
        padding-left: 7%;
        font-weight: 900;
        font-size: 20px;
        color: #0056b3;
      }  
 
}

@media (max-width: 452px) {
  .navbar-logo img {
      margin-left: 20px;
  }
}
