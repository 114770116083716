* {
  margin: 0;
  padding: 0;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

/* .accordion-header{
  border: none;
}
     */

main {
  padding: 0 5%;
}

.container3 {
  display: flex;
  flex-direction: row;
  position: relative;
}
.c2 img {
  margin-top: 3%;
  width: 800px;
  height: 400px;
  border-radius: 20px;
}
.c1 {
  margin-top: 2%;
}
.c1 p {
  padding-right: 25%;
}
.c1 h5 {
  font-size: 28px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 20px;
}
.c1 p {
  font-size: 18px;
  text-align: justify;
  font-weight: 400;
  margin-left: 4%;
  color: rgb(158, 158, 158);
  margin-bottom: 20px;
}
.container3 button {
  font-size: 17px;
  font-weight: 500;
  padding: 3% 5%;
  border-radius: 10px;
  border: none;
  color: white;
  margin-bottom: 3%;
  margin-top: 5%;
  margin-left: 1%;
  cursor: pointer;
  background: linear-gradient(to right, rgb(18, 90, 190), rgb(10, 67, 158));
  background-size: 200% 100%;
  transition: background-position 0.5s;
}

.container3 button:hover {
  background-position: -100% 0;
}

#first-para {
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
}
.image-container {
  display: flex;
  justify-content: space-around;
  margin: 5% 7%;
}

.image-container img {
  width: 200px;
}
.lists h2 {
  font-size: 30px;
  font-weight: 500;
}
/* Style for h3 */
.lists h3 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 3%; /* Adjust as needed */
}

/* Style for p */
.lists p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px; /* Adjust as needed */
}

.lists {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lists ul {
  list-style-type: square;
  font-family: Quicksand;
  font-weight: bold;
  padding: 0px;
  padding: 2% 3%;
  font-size: 18px;
  font-weight: 550;
}
.feature-container {
  display: flex;
  justify-content: center;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  /* padding: 20px; */
}

.feature-card {
  margin: 10px;
  width: 22.5em;
  background-color: white;
  text-align: center;
  border-radius: 20px;
  font-family: "Quicksand";
  box-shadow: 0 0 2em #000000cc;
  transition: transform 0.2s ease-in-out;
}

.row {
  display: flex;
  justify-content: space-evenly;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.card-body {
  margin: 5px;
  padding: 20px;
  text-align: center;
}

.card-body img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 8px;
  height: 4em;
}

.card-body h3 {
  margin: 15px 0;
  font-size: 1.5em;
  color: #171819;
  font-weight: 900;
}

.card-body hr {
  border: 0;
  height: 1px;
  background: #e0e0e0;
  margin: 10px 0;
}

.card-body h6 {
  margin: 10px 0;
  font-size: 1em;
  color: #666666;
}

.card-body h6 strong {
  display: block;
  text-align: left;
  color: #000000;
}
#makeAppointmentBtn {
  background-color:rgb(4, 42, 158);
  color: #fff;
  margin: 3% 20%;
  padding: 1%;
  padding-top: 3%;
  font-family: "Quicksand", sans-serif;
  border: none;
  border-radius: 10px;
}
.lists p {
  text-align: center;
}
.Access-app {
  display: flex;
  justify-content: space-around;
  margin: 2% 3%;
}
.Access-app img {
  width: 450px;
}
.div1 h2 {
  text-align: left;
  font-family: Quicksand;
  font-weight: 700;
  font-size: 2rem;
}
.div1 li {
  color: #323232;
}
h2,
p {
  text-align: center;
}

/* Style the tab */
.tab {
  overflow: hidden;
  display: flex;
  justify-content: space-around;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #0056b3;
  color: white;
  border-radius: 5px;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 2% 5%;
  border-top: none;
}
.div1 ul {
  margin-left: -4%;
}
.div1 li {
  list-style: none;
}
.div1 img {
  width: 2.8%;
  height: 20px;
  margin-top: 5px;
}
.tabcontent {
  text-align: center;
  margin: 2% 0;
}
.tabcontent img {
  width: 60%;
}

/* Add your existing CSS styles here */
.pagination {
  display: flex;
}

/* .pagination-container{
  display: flex;
} */
.cards-pagination {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-evenly;
  /* align-items: center; */
}
.name-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
  max-width: 350px; /* Maximum width of each card */
  border-radius: 20px;
  box-shadow: 4px 2px 4px 2px rgb(140, 140, 140);
  transition: transform 0.5s ease-in-out;
}

.card img {
  width: 90px; /* Adjust image size as needed */
  height: 90px;
  object-fit: cover; /* Ensure images maintain aspect ratio */
  border-radius: 50%;
  margin-bottom: 10px;
}


.card h3 {
  margin-top: 0;
  text-align: start;
  font-size: 1.2rem; /* Adjust heading font size */
}

.card p {
  margin-bottom: 0.5rem;
  color: #666666;
  font-size: 12px;
  font-family: "QuickSand", sans-serif;
  font-weight: bold;
  text-align: justify;
}

.card:nth-child(1) {
  word-spacing: -2px;
}

@media (max-width: 768px) {
  .cards-pagination {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 100%;
    margin-bottom: 20px;
  }

  #makeAppointmentBtn {
    margin-left:35%;
  }
}
h2 {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}

.pagination-dots {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 20px; /* Adjust as needed */
}

.pagination-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #929292;
  background-color: #b1b1b1;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-dot.active {
  background-color: #1f1f1f; /* Active dot color */
}

/* Optional: Increase size and hover effect for better visibility */
.pagination-dot:hover {
  transform: scale(1.2); /* Scale up on hover */
}

/* Optional: Add box-shadow for a subtle hover effect */
.pagination-dot:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Box shadow on hover */
}

/* Optional: Adjust spacing and size for smaller screens */
@media (max-width: 768px) {
  .pagination-dots {
    margin-top: 15px;
  }
  .image-container{
    justify-content: space-between;
  } 

  .image-container img{
    padding-bottom: 10px;
  }


  .pagination-dot {
    width: 10px;
    height: 10px;
    border-width: 1px;
  }
}

.div1 {
  width: 100%;
}

/* General container styling */
#firstDivs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #c6d7e7;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 15px 15px black;
  max-width: 800px;
  margin: 30px auto 0px;
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
}

/* Styling for the left section (message and heading) */
#lookDiv {
  flex: 1;
  text-align: left;
  min-width: 200px;
}

#divContent {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  color: #333;
}

#lookDiv h4 {
  margin: 10px 0 0;
  font-size: 20px;
  color: #666;
}

/* Vertical line styling */
.vl {
  border-left: 2px solid #ddd;
  height: 80px;
  margin: 0 20px;
}

@media (max-width: 600px) {
  .vl {
    display: none; /* Hide vertical line on small screens */
  }
}

/* Styling for the right section (download links) */
#lookDiv2 {
  display: flex;
  gap: 20px;
  flex: 1;
  justify-content: space-around;
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
}

#lookDiv2 #widHeig {
  text-align: center;
}

.store-icon {
  width: 150px;
  height: auto;
  transition: transform 0.3s;
}

.store-icon:hover {
  transform: scale(1.05);
}

@media (max-width: 800px) {
  #lookDiv2 {
    justify-content: center; /* Center icons on smaller screens */
  }

  #firstDivs {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  #divContent {
    font-size: 20px; /* Adjust font size for smaller screens */
  }

  #lookDiv h4 {
    font-size: 18px; /* Adjust font size for smaller screens */
  }

  .store-icon {
    width: 120px; /* Reduce icon size for smaller screens */
  }
  @media (max-width: 832px) {
    .image-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (max-width: 480px) {
  #firstDivs {
    flex-direction: column; /* Stack items vertically on very small screens */
    align-items: center;
    text-align: center;
  }

  #lookDiv {
    margin-bottom: 20px; /* Add space between sections */
  }

  #lookDiv2 {
    gap: 10px; /* Reduce gap between icons */
  }

  .store-icon {
    width: 100px; /* Further reduce icon size */
  }
}
#firstDivs img {
  width: 150px;
}
#firstDivs p {
  text-align: left;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 5%;
}

.pagination-button {
  display: inline-block;
  padding: 7px 14px;
  margin: 0 4px;
  background-color: #008cff96;
  color: #fff;
  border: 1px solid #008cff96;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-button:hover {
  background-color: #0056b3;
}

.pagination-button.active {
  background-color: #0056b3;
  border-color: #0056b3;
}

.pagination-button:disabled {
  background-color: #ddd;
  color: #aaa;
  border-color: #ddd;
  cursor: not-allowed;
}
p {
  text-align: left;
}

.learn-more-btn {
  color: #144be0; /* White text color */
  border: none; /* No border */
  cursor: pointer; /* Cursor style */
  border-radius: 5px; /* Rounded corners */
  margin-top: 10px; /* Margin top */
  font-weight: 550;
  font-size: 18px;
  text-decoration: none;
}

.learn-more-btn:hover {
  color: rgb(9, 137, 242);
}

@media (max-width: 1100px) {
  .Access-app {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    margin: 2% 3%;
  }
  #access {
    width: 100%;
  }
  .container3 {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
  }
  .c2 img {
    margin-top: 3%;
    width: 100%;
    height: auto;
    border-radius: 20px;
  }
  .c1 {
    margin-top: 2%;
  }
  .c1 p {
    padding-right: 5%;
  }
  .c1 h5 {
    font-size: 25px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 20px;
  }
  .c1 p {
    font-size: 16px;
    text-align: justify;
    font-weight: 400;
    margin-left: 4%;
    color: rgb(158, 158, 158);
    margin-bottom: 20px;
  }
  /* .card:nth-child(2) {
      margin-top: 30px;
  } */
  .image-container img {
    padding-left: 5%;
  }
}
@media (max-width: 600px) {
  .image-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .tab {
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .tab button {
    font-size: 15px;
    padding: 2px 10px;
  }
  .tabcontent img {
    width: 100%;
  }
  .image-container img {
    width: 70%;
    margin-top: 5%;
    margin-bottom: 10%;
  }
}
@media (max-width: 450px) {
  .feature-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 0px;
  }

  .feature-card {
    margin: 20px 0px;
    width: 100%;
    width: 100%;
    background-color: white;
    text-align: center;

    border-radius: 4%;
    font-family: "Quicksand";
    box-shadow: 0 0 2em #000000cc;
    transition: transform 0.2s ease-in-out;
  }
  .card {
    margin: 0;
    margin-top: 30px;
  }
}
/* From Uiverse.io by Boosterrohit */ 
.box {
  position: relative;
  width: 40%;
  overflow: hidden;
  border: none;
  background-color: blue;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(104, 134, 218);

}

.box::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 200px;
  margin-top: -92px;
  background:white;
  animation: amm 4s linear infinite;
}

@keyframes amm {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.text-button {
  position: relative;
  font-size: 1.2em;
  font-weight: bold;
  z-index: 1;
}

.box::after {
  content: "";
  position: absolute;
  inset: 1px;
  background-color: rgb(4, 4, 194);
  border-radius: 10px;
}


.merienda {
  font-family: "Merienda", serif;
  font-optical-sizing: auto;
  /* font-weight: <weight>;
  font-style: normal; */
}

.iceberg-regular {
  font-family: "Iceberg", serif;
  font-weight: 400;
  font-style: normal;
  color: rgb(4, 4, 109);
}

.neucha-regular {
  font-family: "Neucha", serif;
  font-weight: 400;
  font-style: normal;
  color: rgb(4, 4, 109);
}

