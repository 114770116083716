.testimonials {
  padding: 2% 5%;
  padding-bottom: 0;
}
.testimonials p {
  text-align: center;
}
.container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
  max-width: 100%;
  margin: auto;
}

.con1 {
  margin-bottom: 13%;
}

.con1 h1 {
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
  text-decoration: underline;
}
.card {
  height: 300px;
}

.con1 p {
  font-size: 1.3em;
  color: #555;
  text-align: left;
}

.con2 {
  display: flex;
  justify-content: center;
}

.con2 img {
  max-width: 100%;
  height: auto;
  padding-left: 2%;
  border-radius: 8px;
}

/* Responsive styles */
@media (min-width: 768px) {
  .container1 {
    flex-direction: row;
    text-align: left;
  }
  #small-image1 {
    margin-top: 2%;
    width: 600px;
    width: 80%;
  }

  .con1 {
    flex: 1;
    padding-right: 20px;
  }

  .con2 {
    flex: 1;
    padding-left: 20px;
  }
}

@media (max-width: 1024px) {
  .con1 h1 {
    font-size: 1.7em;
  }

  .con1 p {
    font-size: 1em;
  }
}
#small-image {
  /* padding-left: 10%; */
  text-align: center;
}
#small-image img{
  height: 150px;
}
#small-image1 {
  margin-top: 2%;
  width: 20%;
  text-align: center;
  padding-left: 10%;
}
/* Add your existing CSS styles here */
.pagination {
  display: flex;
}
.cards-pagination {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
}
.name-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px; /* Maximum width of each card */
  border-radius: 20px;
  box-shadow: 4px 2px 4px 2px rgb(140, 140, 140);
  transition: transform 0.5s ease-in-out;
}

.card img {
  width: 90px; /* Adjust image size as needed */
  height: 90px;
  object-fit: cover; /* Ensure images maintain aspect ratio */
  border-radius: 50%;
  margin-bottom: 10px;
}

.card h3 {
  margin-top: 0;
  text-align: center;
  font-size: 1.2rem; /* Adjust heading font size */
}

.card p {
  margin-bottom: 0.5rem;
  color: #666666;
  font-size: 12px;
  font-family: "QuickSand", sans-serif;
  font-weight: bold;
  text-align: justify;
}

/* .card:nth-child(2) {
  margin-top: 100px; 
} */

@media (max-width: 768px) {
  .cards-pagination {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 100%;
    margin-bottom: 20px;
  }
}
h2 {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}

.pagination-dots {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 20px; /* Adjust as needed */
}

.pagination-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #929292;
  background-color: #b1b1b1;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-dot.active {
  background-color: #1f1f1f; /* Active dot color */
}

/* Optional: Increase size and hover effect for better visibility */
.pagination-dot:hover {
  transform: scale(1.2); /* Scale up on hover */
}

/* Optional: Add box-shadow for a subtle hover effect */
.pagination-dot:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Box shadow on hover */
}
@media (max-width: 1000px) {
  /* .card:nth-child(2) {
    margin-top: 30px;
  } */
}
/* Optional: Adjust spacing and size for smaller screens */
@media (max-width: 768px) {
  .pagination-dots {
    margin-top: 15px;
  }

  .pagination-dot {
    width: 10px;
    height: 10px;
    border-width: 1px;
  }
}

@media (max-width: 450px) {
  .feature-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 0px;
  }

  .feature-card {
    margin: 20px 0px;
    width: 100%;
    width: 100%;
    background-color: white;
    text-align: center;

    border-radius: 4%;
    font-family: "Quicksand";
    box-shadow: 0 0 2em #000000cc;
    transition: transform 0.2s ease-in-out;
  }
  .card {
    margin: 0;
    margin-top: 30px;
  }
  #small-image1 img {
    width: 250px;
  }
}

.iceberg-regular {
  font-family: "Iceberg", serif;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
}

.merienda {
  font-family: "Merienda", serif;
  font-optical-sizing: auto;
  /* font-weight: <weight>;
  font-style: normal; */
}


