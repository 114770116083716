.myteam {
    text-align: center;
}
.myteam img{
    max-height: 700px;
    min-height: 500px;
   
    margin-left: auto;
    margin-right: auto;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

img {
    vertical-align: middle;
    border-style: none;
}
*, ::after, ::before {
    box-sizing: border-box;
}
.title {
    padding: 42px 0 30px 0;
    font-weight: 900;
    font-size: 18px;
    letter-spacing: 5.4px;
}
    .content{
        padding: 2% 0% 0 10% ;
        display: flex;
        justify-content: space-between;
    }
    .content1{
        margin-right: 2%;
    }
    
    .content2 img{
        border: 10px solid rgb(0, 0, 0);
        padding-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
    }
    .about-us {
        font-family: Quicksand;

        padding: 40px 20px;
        text-align: center;
      }
      
      .content2 {
        max-width: 800px;
        margin: 0 auto;
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;
      }
      
      .content2 h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
        text-align: center;

      }
      
      .highlight {
        color: #3399ff;
      }
      .content2 h6{
        font-size: 15px;
        color: rgb(93, 93, 93);
      }
      .content2 p {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 1.5;
      }
      .about3 {
        display: flex;
        align-items: center;
        padding: 40px 20px;
        margin-left:7% ;
        margin-top: 2%;
        margin-bottom: 5%;
      }
      
      .about3-1 {
        flex: 1;
      }
      
      .about3-1 img {
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
      
      .abou3-2 {
        flex: 2;
        padding-left: 10%;
        max-width: 430px;
      }
      
      .abou3-2 h2 {
        font-weight: 900;
        font-size: 18px;
        letter-spacing: 5.4px;
        font-size: 40px;
        text-align: left;
      }
      
      .abou3-2 p {
        font-size: 1.2rem;
        margin-top: 20px;
        line-height: 1.5;
        color: #333;
      }
      
      .about-us-container {
        width:90%;
        padding: 10px 20px 30px 20px;
        margin-left: auto;
        margin-right: auto;
    border-radius: 10px;
    margin-top: 0;
        background-color: #eceef7;
        margin-bottom: 20px;
      }
      .tabcontent{
        margin: 0;
        padding: 1% 2%;
      }
    
      .about-us-content {
        display: flex;
        align-items: center;
        max-width: 1200px;
        margin: 0 auto;
        gap: 20px;
      }

      .image-section {
       
        max-width: 300px;
        height: 150px;
        background-color: #eceef7;
    }
      
      .text-section {
        flex: 2;
      }
      .text-section h6{
        font-size: 1rem;
        margin-bottom: 15px;
        text-align: left;
        margin-top: 0px;

      }
      .text-section h2 {
        font-size: 1.7rem;
        margin-bottom: 5px;
        color: #000000;
        text-align: left;
      }
      .tabs-list p {
        text-align: center;

      }
      
      .tabs-list h1{
        margin-top: 25px;
        text-align: center;

      }
      .text-section p   {
        font-size: 1rem;
        text-align: left;
      }
      @media (max-width:1000px) {
     
      .myteam img{
          max-height: auto;
          min-height: auto;
          width: 100%;
        
      }
      
    .content{
              padding: 2% 0% 0 2% ;
              display: flex;
              justify-content: space-between;
              flex-direction: column;
          }
  
          
          .content2 img{
              border: 10px solid rgb(0, 0, 0);
              padding-right: 10px;
          margin-top: auto;
          margin-bottom: auto;
          width: 100%;
          }
          .about-us {
              font-family: Quicksand;
      
              padding: 40px 20px;
              text-align: center;
            }
            
            .content2 {
              max-width: 800px;
              margin: 0 auto;
              text-align: center;
              margin-top: auto;
              margin-bottom: auto;
            }
            
            .content2 h1 {
              font-size: 2.5rem;
              margin-bottom: 20px;
              text-align: center;
      
            }
            
            .highlight {
              color: #3399ff;
            }
            .content2 h6{
              font-size: 15px;
              color: rgb(93, 93, 93);
            }
            .content2 p {
              text-align: center;
              font-size: 18px;
              font-weight: 500;
              margin-bottom: 20px;
              line-height: 1.5;
            }
            .about3 {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 40px 0px;
              margin-left:0% ;
              margin-top: 2%;
              margin-bottom: 5%;
            }
            
            .about3-1 {
              flex: 1;
            }
            
            .about3-1 img {
              width: 100%;
              height: auto;
              border-radius: 10px;
            }
            
            .abou3-2 {
              flex: 2;
              padding-left: 2%;
              max-width: 100%;
              width: 100%;
            }
            
            .abou3-2 h2 {
              font-weight: 900;
              font-size: 18px;
              letter-spacing: 5.4px;
              font-size: 40px;
              width: 100%;
              text-align: left;
            }
            
            
            
            .about-us-container {
              width:100%;
              padding: 10px 20px 30px 20px;
              margin-left: auto;
              margin-right: auto;
          border-radius: 10px;
          margin-top: 0;
              background-color: #eceef7;
              margin-bottom: 20px;
            }
            .tabcontent{
              margin: 0;
              padding: 1% 2%;
            }
          
            .about-us-content {
              display: flex;
              flex-direction: column;
              align-items: center;
              max-width: 1200px;
              margin: 0 auto;
              gap: 20px;
            }
      
            .image-section {
             
              max-width: 300px;
              height: 150px;
              background-color: #eceef7;
          }
            
            .text-section {
              flex: 2;
            }
            .text-section h6{
              font-size: 1rem;
              margin-bottom: 15px;
              text-align: left;
              margin-top: 0px;
      
            }
            .text-section h2 {
              font-size: 1.7rem;
              margin-bottom: 5px;
              color: #000000;
              text-align: left;
            }
            .tabs-list p {
              text-align: center;
      
            }
            
            .tabs-list h1{
              margin-top: 25px;
              text-align: center;
              font-size: 33px;
      
            }
            .text-section p   {
              font-size: 1rem;
              text-align: left;
            }
            
      }
      @media (max-width:600px) {
        .tabcontent img{
          width: 60%;
        }
        .text-section h6{
          font-size: 1rem;
          margin-bottom: 15px;
          text-align: left;
          margin-top: 0px;
  
        }
        .text-section h2 {
          font-size: 1.5rem;
          margin-bottom: 5px;
          color: #000000;
          text-align: left;
        }
        .tabs-list p {
          text-align: justify;
  font-size: 3vw;
        }
        
      }

      .merienda{
        font-family: "Merienda", serif;
        font-optical-sizing: auto;
        /* font-weight: <weight>;
        font-style: normal; */
      }
      .iceberg-regular {
        font-family: "Iceberg", serif;
        font-weight: 400;
        font-style: normal;
      }
      