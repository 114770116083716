.body{
  background-color: #e6f0ff; /* Light blue background */
}
.contact-form {
    width: 30%;
    margin: 0 auto;
    padding: 2rem;
    background-color: #e6f0ff; /* Light blue background */
    border-radius: 8px;
    text-align: center;
  }
  
  .contact-form .header h2 {
    color: #656464;
    font-size: 17px;
    width: 100%;
  }
  .error-input {
    border: 2px solid red; /* or use a different style */
    background-color: #ffe6e6; /* light red background */
  }
  
  
  .contact-form h1 {
    text-align: center;
    color: #00008b;
    width: 400px;
    font-weight: bolder;
    font-size: 40px;
    width: 100%;
    font-family: Quicksand;
}
  
  
  .contact-form p {
    margin-bottom: 2rem;
    color: #555;
  }
  
  .contact-form .form-group {
    margin-bottom: 1.5rem;
    text-align: left;
  }
  
  .contact-form .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .contact-form .form-group input,
  .contact-form .form-group textarea {
    width: 100%;
    padding: 0.5rem;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .contact-form .form-group input[type="text"],
  .contact-form .form-group input[type="email"] {
    height: 40px;
  }
  
  .contact-form .form-group textarea {
    resize: vertical;
    height: 100px;
  }
  
  .contact-form button {
    font-size: 20px;
    color: #fff;
    padding: 1% 8%;
    background-color: #00008b;
    border: none;
    border-radius: 7px;
    width: 100%;
}
  
  
  .contact-form button:hover {
    background-color: #0760d4;
    padding: 2%;
  }



 .bottom{
    margin-top: 2rem;
    text-align: left;
  }
  
 .bottom h3 {
    text-align: center;
    color: #00008b;
    width: 100%;
    font-weight: bolder;
font-size: 1.7rem;
font-family: Quicksand;
margin-bottom: 10px;
  }
  
 .bottom p {
    margin-bottom: 0.5rem;
    color: #555;
    font-weight: bold;
    text-align: center;
  }
  
 .bottom a {
    text-decoration: none;
    display: block;
    text-align: center;
    margin-bottom: 1rem;
  }
 .bottoma:hover {
    text-decoration: underline;
  }
  
 .bottom address {
    font-style: normal;
    color: #555;
    line-height: 1.5;
    text-align: center;
  }
  @media (max-width:1200px) {
    .contact-form {
      width: 60%;
      margin: 0 auto;
      padding: 2rem;
      background-color: #e6f0ff; /* Light blue background */
      border-radius: 8px;
      text-align: center;
    }
  }
  @media (max-width:600px) {
    .contact-form {
      width: 100%;
      margin: 0 auto;
      padding: 2rem;
      background-color: #e6f0ff; /* Light blue background */
      border-radius: 8px;
      text-align: center;
    }

  }


  .login {
    width: 100%;
    /* height: 400px; */
    /* background: #2c2c2c; */
    /* padding: 47px; */
    padding-bottom: 57px;
    /* color: #fff; */
    border-radius: 17px;
    padding-bottom: 50px;
    font-size: 1.3em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }


  .login input:focus {
    animation: bounce 1s;
    /* -webkit-appearance: none; */
  }

  .login input[type=submit],
.login input[type=button],
.h1 {
  border: 0;
  outline: 0;
  width: 100%;
  padding: 13px;
  margin: 40px 0 0 0;
  border-radius: 500px;
  font-weight: 600;
  animation: bounce2 1.6s;
}

.login input[type=text] {
  animation: bounce 1s;
  /* -webkit-appearance: none; */
}

.login input[type=email] {
  animation: bounce1 1.3s;
}
.login textarea{
  animation: bounce1 1.5s;
}
.ui {
  font-weight: bolder;
  background: -webkit-linear-gradient(#B563FF, #535EFC, #0EC8EE);
  /* -webkit-background-clip: text; */
  -webkit-text-fill-color: transparent;
  border-bottom: 4px solid transparent;
  border-image: linear-gradient(0.25turn, #535EFC, #0EC8EE, #0EC8EE);
  border-image-slice: 1;
  display: inline;
}


@media only screen and (max-width: 600px) {
  .login {
    width: 100%;
    padding: 3em;
  }
  login input{
    width: 100px;
  }
  
}

@keyframes bounce {
  0% {
    transform: translateY(-250px);
    opacity: 0;
  }
}

@keyframes bounce1 {
  0% {
    opacity: 0;
  }

  40% {
    transform: translateY(-100px);
    opacity: 0;
  }
}

@keyframes bounce2 {
  0% {
    opacity: 0;
  }

  70% {
    transform: translateY(-20px);
    opacity: 0;
  }
}
.header{
  animation: bounce1 0.8s;
}
.contact-form label{
  font-size: 18px;
  font-family: "Iceberg", serif;
  color: black;
}

.merienda {
  font-family: "Merienda", serif;
  font-optical-sizing: auto;
  /* font-weight: <weight>;
  font-style: normal; */
}
.iceberg-regular {
  font-family: "Iceberg", serif;
  font-weight: 400;
  font-style: normal;
}