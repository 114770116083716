.App {
  text-align: left;
  z-index: -1;

}


.footer {
  border-top-left-radius: 25%;
  border-top-right-radius: 25%;
  background: #000;
  color: #fff;
  padding: 20px;
}

.footer-content {
  padding-top: 5%;

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.company-info h2 {
  color: #42a5f5; /* Adjust the color to match the one in the image */
}

.quick-links ul, .support-links ul {
  list-style: none;
  padding: 0;
}


.quick-links li, .support-links li {
  margin: 5px 0;
  text-decoration: none;
    color: #fff;
    opacity: .75;
    font-size: small;
    font-weight: bold;
}
#butterfly{
  width: 101px;
  margin-left: 20%;
}

.quick-links a, .support-links a {
  color: #42a5f5; /* Adjust the color to match the one in the image */
  text-decoration: none;
}

.social-links a img {
  width: 30px; /* Adjust size as needed */
  margin: 10px;
}
.slab3 p {
  font-size: 16px;
  letter-spacing: 3px;
  font-weight: 200;
  color: #dddcdc;
  text-align: center;
  margin: 2% 0;
}


address{
  padding: 0 20%;
  margin-left: 5%;
  font-size: 14px;
  text-align: left;
  text-decoration: none;
    color: #fff;
    opacity: .55;
    font-size: small;
}
@media (max-width:460px) {
  address{
  padding: 0 10%;
  margin-left:0%;
  text-align: center;
  font-size: 14px;
  text-decoration: none;
    color: #fff;
    opacity: .55;
    font-size: small;
}
}