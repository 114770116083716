

.onboarding-container {
  display: flex;
  align-items: center;
  background-color: #e6f0ff; /* Light blue background */
  padding:  0 8%;
}

.contents {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;
}

h1 {
  padding: 0 2%;
  color: #003384;
  font-size: 68px;
  font-family: 'Gilroy' !important;
  font-weight: 900;
  line-height: 110%;
  margin: 0 0 0.8em;
  word-wrap: break-word;
  letter-spacing: -.02em;
  text-align: left;
}

.buttons {
  display: flex;
  gap: 10px;
  padding: 0 3% ;
}

.demo-button {
  background-color: #0068D9 !important; /* Dark blue button */
  color: white;
  padding: 15px 50px;
  border: none;
  font-weight: bold;
  border-radius: 30px;
  font-size: 18px;
  cursor: pointer;
}

.tour-link {
  display: inline-block;
  color: #0044cc; /* Dark blue text */
  font-size: 18px;
  text-decoration: none;
  padding: 10px 20px;
  border: 1px solid #0044cc; /* Dark blue border */
  border-radius: 5px;
}

.images {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.image-row {
  display: flex;
  gap: 10px;
}

.image-row img {
  border-radius: 10px;
}


#pgrid {
  padding-top: 4%;
  text-align: center;
  font-size: 16px; /* Adjust font size */
  font-weight: 500; /* Make text bold */
  color: #333; /* Set text color */
}

#h2grid {
  color: #003384;
  font-size: 32px !important;
  font-family: 'Gilroy' !important;
  font-weight: 900;
  line-height: 120%;
  margin-bottom: 20px;

}

#p2grid {
  color: #292E32;
  text-align: center;
  font-size: 20px;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 150%;
  max-width: 950px;
  margin: auto;
padding-bottom: 3% ;}

.main-sol {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding:  0% 10%;
}
.description {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.section-text {
  padding: 20px;
  border-radius: 8px;
}
.section-image{
  max-width: 100%;
  height: auto;
  border: 0;
  vertical-align: middle;
}
.section-text h2 {
  margin-top: 0;
  text-align: left;
  color: #003384;
  font-family: 'Gilroy';
  font-weight: bold;
  font-size: 24px;
  font-weight: 900;
  line-height: 120%;
  padding-bottom: 7px;
}

.section-text p {
  color: #292E32;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  margin-bottom: 72px;
}

.Book-main {
margin: 3% 10%;
border-radius: 50px;
padding: 20px;
  display: flex;
  align-items: center; /* Centers items vertically */
  justify-content: space-around; /* Distributes space between the image and description */
  background-color: #e6f0ff; /* Light blue background */
}

.Book-main img {
  max-width: 50%; /* Ensures the image takes up no more than half the container */
  height: auto; /* Maintains aspect ratio */
}

.description-image {
  max-width: 45%; /* Ensures the description takes up the remaining space */
  margin-left: 20px; /* Adds some space between the image and the description */
  display: flex;
  flex-direction: column; /* Arranges child elements vertically */
  align-items: flex-start; /* Aligns child elements to the start of the flex container */
}


.demo-button :hover {
  background-color: #004187; /* Darker shade for hover effect */
}

.description-image h2 {
  color: #003384;
  font-size: 32px !important;
  font-family: 'Gilroy' !important;
  font-weight: 900;
  line-height: 120%;
  margin-bottom: 20px;
}

.description-image p {
  margin-bottom: 20px;
  color: #292E32;
  font-size: 18px;
  font-family: 'Open Sans';
  font-weight: 400;
  line-height: 160%;
}
.extra {
  background-color: #e6f0ff; /* Light blue background */
  padding: 20px; /* Space around content */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  max-width: 100%; /* Maximum width of container */
  margin: 20px 10%;
  text-align: center; /* Center align text and button */
}

.extra h2 {
  color: #003384;
  font-size: 40px;
  font-family: 'Gilroy' !important;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  margin-bottom: 20px;
}

.extra p {
  text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}


.demo-button2 {
  background-color: #ffffff; /* Blue background color */
  color: #2468ef; /* White text color */
  border: 2px solid #2468ef;
  border-radius: 30px; /* Rounded corners for the button */
  padding: 15px 50px; /* Space inside the button */
  font-size: 16px; /* Font size for the button text */
  cursor: pointer; /* Pointer cursor on hover */
  margin-top: 2%;
  transition: background-color 0.3s ease; /* Smooth color transition */
  font-weight: bold;
}

.demo-button2:hover {
  color: white;
  background-color: #0056b3; /* Darker blue on hover */
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;
}
.main-main{
  display: flex;
  margin:2% 10%;
}
/* Style the tab */
.tab1 {
  display: flex;
  flex-direction: column;
  float: left;
  width: 30%;
  height: 300px;
}

/* Style the buttons inside the tab */
.tab1 button {
  display: block;
  background-color: inherit;
  color: rgb(138, 138, 138);
  padding: 22px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
  font-weight: 700;

}

/* Change background color of buttons on hover */
.tab1 button:hover {
  background-color: #ffffff;
}

/* Create an active/current "tab button" class */
.tab1 button.active {
  text-decoration: none;
  color: #004187;
  
}

/* Style the tab content */
.tabcontent1 {
  float: left;
  padding: 0px 12px;
  width: 70%;
  border-left: none;
  height: auto;
  display: none; /* Ensure content is hidden by default */
}
.main-main h3{
  color: rgb(0, 0, 154);
  font-size: 20px ;
  font-weight: bold;
}
.main-main p{
  color: #292E32;
    font-size: 16px;
    font-family: 'Open Sans';
    font-weight: 400;
    line-height: 160%;
}
/* Media Queries for Responsive Design */
@media (max-width: 1000px) {
  .onboarding-container {
    flex-direction: column;
    padding: 0 5%;
  }

  h1 {
    font-size: 48px;
    text-align: center;
  }

  .buttons {
    flex-direction: column;
    align-items: center;
  }

  .Book-main {
    flex-direction: column;
  }

  .description-image {
    max-width: 100%;
    margin: 0;
  }

  .main-sol {
    grid-template-columns: 1fr;
    padding: 0;
  }

  .description {
    grid-template-columns: 1fr;
  }

  .tab1 {
    width: 100%;
  }

  .tabcontent1 {
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 36px;
  }

  .demo-button, .demo-button2 {
    font-size: 16px;
    padding: 12px 30px;
  }

  .Book-main img {
    max-width: 100%;
  }

  .extra h2 {
    font-size: 32px;
  }

  .main-main {
    flex-direction: column;
    margin: 2% 5%;
  }
  .image-row {
   flex-wrap: wrap;
  }
  
  .tabcontent1 {
    width: 100%;
    padding: 0;
    display: block; /* Show all tab content on small screens */
  }
}
